<template>
    <v-container class="my-5">
        <v-card>
            <v-card-title>
                <span class="headline primary--text">Title</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12"
                               sm="12">
                            <v-text-field v-model="item.name"
                                          label="Название"/>
                        </v-col>
                        <v-col>
                            <v-card>
                                <v-card-text>
                                    <v-layout row wrap class="pa-3" v-for="(f, index) in item.fields" :key="index">
                                        <v-flex xs2 class="ml-3">
                                            <div>
                                                <v-text-field v-model="f.key" label="Ключ"/>
                                            </div>
                                        </v-flex>
                                        <v-flex xs2 class="ml-3">
                                            <v-text-field v-model="f.label" label="Отображение"/>
                                        </v-flex>
                                        <v-flex xs2 class="ml-3">
                                            <v-select v-model="f.type" :items="types" label="Тип"/>
                                        </v-flex>
                                        <v-flex xs2 class="ml-3">
                                            <v-checkbox v-model="f.editable" label="Можно редактировать"
                                                        color="primary"/>
                                        </v-flex>
                                        <v-flex xs1 class="ml-3">
                                            <v-btn text>
                                                <v-icon color="primary"
                                                        text
                                                        @click="remove(f)">mdi-minus-box
                                                </v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                    <v-btn text>
                                        <v-icon color="primary"
                                                text
                                                @click="add">mdi-plus-box
                                        </v-icon>
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn color="primary"
                       text
                       @click="close">Отменить
                </v-btn>
                <v-btn color="primary"
                       text
                       @click="save">Сохранить
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>

    import {uuid} from "uuidv4";

    export default {
        data: () => ({
            item: {
                id: uuid.Nil,
                name: "",
                icon: "",
                fields: []
            },
            types: [
                {text: "строка", value: 'string'},
                {text: "число", value: 'number'},
                {text: "дата", value: 'date'},
                {text: "изображение", value: 'image'}
            ]
        }),

        created() {
            this.$store.dispatch("documentTypes/fetch");

            if (this.current) {
                this.item = Object.assign({}, this.current);
            }
        },

        computed: {
            current() {
                return this.$store.getters["documentTypes/item"](this.$route.params.id);
            }
        },

        watch: {
            current(newValue) {
                if (newValue) {
                    this.item = Object.assign({}, newValue);
                }
            }
        },

        methods: {
            isNew() {
                if (!this.item) {
                    return true
                }
                return this.item.id === uuid.Nil;
            },

            add() {
                this.item.fields.push({key: "", label: "", type: "string"})
            },

            remove(item) {
                this.item.fields = this.item.fields.filter(i => {
                    return item.key !== i.key;
                });
            },

            save() {
                this.$store.dispatch("documentTypes/save", Object.assign({}, this.item));
                this.close();
            },

            close() {
                this.item.id = uuid.Nil;
                this.item.name = "";
                this.item.icon = "";
                this.item.fields = "";

                this.$router.push({name: 'documentType'})
            }
        }
    };
</script>